import React from 'react'
import Layout from '@components/layouts/default/Layout'
import ServicesWrapper from '@components/ui/wrappers/ServicesWrapper'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import { OpenModalContext } from '@components/layouts/default/Layout'
import modalContactTypes from '@configs/modalContactTypes'

const Privacy_Policy = ({ intl }) => {
  return (
    <Layout pageName="privacy-policy">
      <OpenModalContext.Consumer>
        {(value) => {
          return (
            <ServicesWrapper>
              <h1>{intl.formatMessage({ id: 'privacy-policy.title' })}</h1>
              <p className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.lastUpdated' })}</p>
              <p>
                {intl.formatMessage({ id: 'privacy-policy.thisPrivacyPolicy' })}
                <Link className="inkInside" to="/">
                  ideabuddy.com
                </Link>
                {intl.formatMessage({ id: 'privacy-policy.inThisPolicy' })}
              </p>
              <ol>
                {intl.formatMessage({ id: 'privacy-policy.topics' })}
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.whatData' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.howDoWeCollect' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.howWillWeUse' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.howLongDoWeStore' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.whatAreYourData' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.areYourIdeasSafe' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.whatAreCookies' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.howDoWeUse' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.whatTypesOfCookies' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.howToManage' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.privacyPoliciesOfOtherWebsites' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.changesToOurPrivacyPolicy' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.howToContactUs' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.ourDetails' })}</li>
              </ol>
              <p>{intl.formatMessage({ id: 'privacy-policy.byReadingThisPrivacyPolicy' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.1WhatDataDoWeCollect' })}</h2>
              <ul className="paddingVertical">
                {intl.formatMessage({ id: 'privacy-policy.weCollectTheFollowingData' })}

                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.personalIdentification' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.yourIpAddress' })}</li>
              </ul>
              <p>{intl.formatMessage({ id: 'privacy-policy.theLegalBasis' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.2HowDoWeCollect' })}</h2>
              <ul>
                {intl.formatMessage({ id: 'privacy-policy.youDirectlyProvide' })}
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.registerForFree' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.voluntarilyComplete' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.useOrView' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.googleAnalytics' })}</li>
              </ul>
              <h2>{intl.formatMessage({ id: 'privacy-policy.3HowWillWeUse' })}</h2>
              <ul>
                {intl.formatMessage({ id: 'privacy-policy.weCollectYourData' })}
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.emailYouInfo' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.offerYouSubscription' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.analyzeTheUse' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.secureOperating' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.processInformation' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.provideTechnical' })}</li>
              </ul>
              <p>{intl.formatMessage({ id: 'privacy-policy.weMayProcessAnyOfYourPersonalData' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.weCanAlsoUseAnyOfYourPersonalData' })}</p>
              <ul>
                {intl.formatMessage({ id: 'privacy-policy.ifYouAgree' })}
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.assistingUsWith' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingCloud' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.servingAdvertisements' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingCustomer' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.enablingUsToSend' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingWebAnalytics' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingSubscription' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingVideo' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingIssueTracking' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.monitoringErrors' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.offeringMarketing' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.assistingAppointment' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.providingOnline' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.providingWeb' })}</li>
              </ul>
              <p>{intl.formatMessage({ id: 'privacy-policy.theLegalBasisFor' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.4HowLong' })}</h2>
              <p>{intl.formatMessage({ id: 'privacy-policy.personalDataThatWeProcess' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.weMayRetain' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.5WhatAreYourData' })}</h2>
              <p>{intl.formatMessage({ id: 'privacy-policy.ourCompany' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.theRightToAccess' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.theRightToRectification' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.theRightToErasure' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.theRightToRestrict' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.theRightToObject' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.theRightToData' })}</p>
              <p>
                {intl.formatMessage({ id: 'privacy-policy.ifYouMakeRequest' })}
                <span
                  onClick={() => {
                    value.openContact(modalContactTypes.CONTACT)
                  }}
                  className="spanLink leftElement"
                  aria-hidden="true"
                >
                  support@ideabuddy.com
                </span>
              </p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.6AreYourIdeas' })}</h2>
              <p>{intl.formatMessage({ id: 'privacy-policy.yourIdeasWillRemainSecure' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.deepBlueSeaStudioDoesNotClaim' })}</p>
              <p>{intl.formatMessage({ id: 'privacy-policy.allOfTheEntries' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.7Cookies' })}</h2>
              <p>{intl.formatMessage({ id: 'privacy-policy.cookiesAreTextFiles' })}</p>
              <p>
                {intl.formatMessage({ id: 'privacy-policy.forFurther' })}
                <a
                  href="https://allaboutcookies.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="leftElement"
                >
                  allaboutcookies.org
                </a>
                .
              </p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.8How' })}</h2>
              <ul>
                {intl.formatMessage({ id: 'privacy-policy.weUseCookies' })}
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.keeping' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.understanding' })}</li>
              </ul>
              <h2>{intl.formatMessage({ id: 'privacy-policy.9What' })}</h2>
              <ul>
                {intl.formatMessage({ id: 'privacy-policy.thereAreNumber' })}
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.functionalityWeUseThese' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.advertisingWeUseThese' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.statisticWeMayUse' })}</li>
                <li className="marginBottom">{intl.formatMessage({ id: 'privacy-policy.necessaryAfterTheLaunch' })}</li>
              </ul>
              <h2>{intl.formatMessage({ id: 'privacy-policy.10How' })}</h2>
              <p>
                {intl.formatMessage({ id: 'privacy-policy.youCanSet' })}
                <Link to="/cookie-statement" className="leftElement">
                  {intl.formatMessage({ id: 'privacy-policy.cookieStatement' })}
                </Link>
                {intl.formatMessage({ id: 'privacy-policy.however' })}
              </p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.11Privacy' })}</h2>
              <p>{intl.formatMessage({ id: 'privacy-policy.ourWebsite' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.12Changes' })}</h2>
              <p>{intl.formatMessage({ id: 'privacy-policy.weKeepOurPrivacy' })}</p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.13How' })}</h2>
              <p>
                {intl.formatMessage({ id: 'privacy-policy.ifIouHaveAnyQuestions' })}
                <span
                  onClick={() => {
                    value.openContact(modalContactTypes.CONTACT)
                  }}
                  className="spanLink leftElement"
                  aria-hidden="true"
                >
                  support@ideabuddy.com
                </span>
                .
              </p>
              <h2>{intl.formatMessage({ id: 'privacy-policy.14Our' })}</h2>
              <ol>
                <li className="marginTop">{intl.formatMessage({ id: 'privacy-policy.thisWebsite' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.weAreRegistered' })}</li>
                <li>{intl.formatMessage({ id: 'privacy-policy.youCanContactUs' })}</li>
              </ol>
            </ServicesWrapper>
          )
        }}
      </OpenModalContext.Consumer>
    </Layout>
  )
}
export default injectIntl(Privacy_Policy)
